exports.components = {
  "component---src-pages-christian-tsx": () => import("./../../../src/pages/christian.tsx" /* webpackChunkName: "component---src-pages-christian-tsx" */),
  "component---src-pages-christopher-tsx": () => import("./../../../src/pages/christopher.tsx" /* webpackChunkName: "component---src-pages-christopher-tsx" */),
  "component---src-pages-frieder-tsx": () => import("./../../../src/pages/frieder.tsx" /* webpackChunkName: "component---src-pages-frieder-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jana-tsx": () => import("./../../../src/pages/jana.tsx" /* webpackChunkName: "component---src-pages-jana-tsx" */),
  "component---src-pages-ronja-tsx": () => import("./../../../src/pages/ronja.tsx" /* webpackChunkName: "component---src-pages-ronja-tsx" */)
}

